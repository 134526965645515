import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

import Layout from "../components/layout";
import Loading from "../components/loading";
import Pagination from "../components/pagination";
import { DefDatePicker } from "../components/datepicker";

import { CurrentUserContext } from "../providers/auth";
import Services, { hasAuthError } from "../services";
import UserUtil from "../utils/user";
import Links from "../utils/links";
import { formatDate, timeFormats } from "../utils/time";
import { lamppostDisplayName } from "../utils/lamppost";

const LogsPage = () => {
    const [checkCount, setCheckCount] = useState(0);

    const forceCheckSession = () => {
        setCheckCount(checkCount + 1);
    };

    return (
        <Layout
            link={Links.Logs}
            seoTitle="Logs"
            permissionValidator={UserUtil.hasDataOperatorPrivileges}
            forceCheckSessionCount={checkCount}
        >
            <LogsView forceCheckSession={forceCheckSession} />
        </Layout>
    );
};

export default LogsPage;

const SAVED_VALIDATION = "SAVED_VALIDATION";
const ActionLabels = [
    SAVED_VALIDATION,
    "SUBMITTED_VALIDATION",
    "WITHDREW_VALIDATION_SUBMISSION",
    "APPROVED_VALIDATION",
    "REJECTED_VALIDATION",
    "IMPORTED_DATASET",
    "REMOVED_IMPORTED_DATASET",
    "ADDED_USER",
    "UPDATED_USER",
    "REMOVED_USER",
    "ADDED_LAMPPOST",
    "UPDATED_LAMPPOST",
    "REMOVED_LAMPPOST",
    "UPDATED_SYS_PARAM"
].map((a) => Object.assign({}, { name: a.replace(/_/g, " "), value: a }));

const ActionNameMap = ActionLabels.reduce((dict, al) => {
    dict[al.value] = al.name;
    return dict;
}, {});

const FieldLabelDict = {
    no_conc: "NO conc.",
    no2_conc: "NO2 conc.",
    pm25_conc: "PM2.5 conc.",
    no_flag: "NO flag",
    no2_flag: "NO2 flag",
    pm25_flag: "PM2.5 flag"
};

const LogsView = ({ forceCheckSession }) => {
    const currentUser = useContext(CurrentUserContext);
    const logService = Services(currentUser).log;
    const lamppostService = Services(currentUser).lamppost;

    const [loading, setLoading] = useState(false);
    const [lamppostDict, setLamppostDict] = useState(null);
    const [logs, setLogs] = useState(null);
    const [selLogIdx, setSelLogIdx] = useState(null);
    const [validationLogs, setValidationLogs] = useState(null);
    // Filter options: Username, time from/to, action type
    const [username, setUsername] = useState("");
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [actionType, setActionType] = useState("");

    const handleAuthError = (err) => {
        if (hasAuthError(err)) {
            forceCheckSession();
        }
    };

    const formatRecordDate = (date) =>
        date && formatDate(date, timeFormats.YYYYMMDD);

    const changePage = (curPage) => {
        setLoading(true);
        setLogs(null);
        logService
            .getLogs({
                page: curPage,
                username,
                from: formatRecordDate(from),
                to: formatRecordDate(to),
                actionType
            })
            .then((d) => setLogs(d))
            .catch((err) => {
                handleAuthError(err);
                setLogs(null);
                console.error(err);
            })
            .finally(() => setLoading(false));
    };

    const resetOptions = () => {
        setUsername("");
        setFrom(null);
        setTo(null);
        setActionType("");
    };

    const getValidationLogs = (log) => {
        const { username, record_time } = log;
        logService
            .getDataValidationLogs({
                validatedBy: username,
                validatedAt: record_time
            })
            .then((d) => {
                setValidationLogs({
                    username,
                    record_time,
                    logs: d.logs
                });
            })
            .catch((err) => {
                handleAuthError(err);
                setValidationLogs(null);
                console.error(err);
            });
    };

    useEffect(() => {
        if (!currentUser) {
            return;
        }
        lamppostService
            .getLampposts()
            .then((d) => {
                setLamppostDict(
                    d.lampposts.reduce((dict, l) => {
                        dict[l.lamppost_id] = l;
                        return dict;
                    }, {})
                );
            })
            .catch((err) => {
                handleAuthError(err);
                setLamppostDict({});
            });
        changePage();
    }, [currentUser]);

    const options = () => (
        <div>
            <div className="mt-3">
                <div className="flex flex-row items-center">
                    <div
                        className="label text-right"
                        style={{ width: "100px" }}
                    >
                        Date:
                    </div>
                    <DefDatePicker
                        selected={from}
                        onChange={(d) => setFrom(d)}
                    />
                    <span className="mx-4">to</span>
                    <DefDatePicker selected={to} onChange={(d) => setTo(d)} />
                </div>
            </div>
            <div>
                <div className="mt-3 inline-block">
                    <div
                        className="inline-block label text-right"
                        style={{ width: "100px" }}
                    >
                        Username:
                    </div>
                    <input
                        className="input-inline mr-20"
                        style={{ width: "200px" }}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div className="mt-3 inline-block">
                    <span className="label">Action:</span>
                    <select
                        className="input-inline"
                        style={{ width: "200px" }}
                        value={actionType}
                        onChange={(e) => setActionType(e.target.value)}
                    >
                        <option value="">All</option>
                        {ActionLabels.map((al, idx) => (
                            <option key={idx} value={al.value}>
                                {al.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );

    const LogsTable = () => (
        <table className="w-full admin-table my-2">
            <thead>
                <tr>
                    <th style={{ width: "15%" }}>Time (HKT)</th>
                    <th style={{ width: "13%" }}>Username</th>
                    <th style={{ width: "26%" }}>Action</th>
                    <th style={{ width: "46%" }}>Remark</th>
                </tr>
            </thead>
            <tbody>
                {logs.logs.map((l, idx) => (
                    <tr
                        key={idx}
                        className={idx === selLogIdx ? "bg-blue-200" : ""}
                    >
                        <td>
                            {formatDate(
                                new Date(l.record_time),
                                timeFormats.dayTime
                            )}
                        </td>
                        <td>{l.username}</td>
                        <td>
                            {ActionNameMap[l.action_type]}{" "}
                            {l.action_type === SAVED_VALIDATION && (
                                <div>
                                    <button
                                        className="btn-secondary"
                                        onClick={() => {
                                            setSelLogIdx(idx);
                                            getValidationLogs(l);
                                        }}
                                    >
                                        View Validation Change Logs
                                    </button>
                                </div>
                            )}
                        </td>
                        <td>{l.remarks}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );

    return (
        <>
            <div className="title-h1">Log</div>
            <div className="lg:flex lg:flex-row p-4 bg-gray-200 mb-4">
                {options()}
                <div className="lg:flex-1" />
                <div
                    className="lg:flex lg:flex-col mt-3 text-center"
                    style={{ minWidth: "150px" }}
                >
                    <button
                        className="btn-primary mx-2"
                        onClick={() => changePage()}
                    >
                        <span className="px-4 inline-block">Apply Filter</span>
                    </button>
                    <button
                        className="btn-secondary mt-3 mx-2"
                        onClick={resetOptions}
                    >
                        Reset Options
                    </button>
                </div>
            </div>
            {loading ? (
                <Loading />
            ) : (
                logs && (
                    <>
                        {logs.logs.length !== 0 ? (
                            <LogsTable />
                        ) : (
                            <div className="text-center py-32">
                                No matched logs found.
                            </div>
                        )}
                        <Pagination
                            curPage={logs.curr_page}
                            totalPages={logs.total_pages}
                            changePageFunc={changePage}
                        />
                    </>
                )
            )}

            {lamppostDict && validationLogs && (
                <ValidationLogs
                    logs={validationLogs}
                    lamppostDict={lamppostDict}
                    onClose={() => {
                        setSelLogIdx(null);
                        setValidationLogs(null);
                    }}
                />
            )}
        </>
    );
};

LogsView.propTypes = {
    forceCheckSession: PropTypes.func.isRequired
};

const getValiationLogDisplayVal = (vlog) => {
    const field = vlog.updated_field;
    if (field === "no_conc" || field === "no2_conc" || field === "pm25_conc") {
        return vlog.updated_conc !== null ? "" + vlog.updated_conc : "";
    }
    return vlog.updated_flag !== null ? "" + vlog.updated_flag : "";
};

const ValidationLogs = ({ logs, lamppostDict, onClose }) => (
    <div
        className="fixed shadow-lg border bg-white p-4 z-50 w-3/5 right-0 mr-8"
        style={{ top: "80px", bottom: "40px" }}
    >
        <div style={{ height: "90%" }} className="overflow-auto">
            <div className="title-h2">Change Logs of Data Validation</div>
            {logs.logs.length !== 0 && (
                <div className="mt-2">
                    <span className="label">Lamppost:</span>
                    {lamppostDisplayName(
                        lamppostDict[logs.logs[0].lamppost_id]
                    ) || logs.logs[0].lamppost_id}
                </div>
            )}
            <div className="my-2">
                <span className="label">Validated By:</span>
                {logs.username}
                <span className="ml-20 label">Validated Time (HKT):</span>
                {formatDate(new Date(logs.record_time), timeFormats.dayTime)}
            </div>
            <table className="w-full admin-table">
                <thead>
                    <tr>
                        <th style={{ width: "40%" }}>Time (HKT)</th>
                        <th style={{ width: "30%" }}>Updated Field</th>
                        <th style={{ width: "30%" }}>Updated Value</th>
                    </tr>
                </thead>
                <tbody>
                    {logs.logs.map((l, idx) => (
                        <tr key={idx}>
                            <td>
                                {formatDate(
                                    new Date(l.observation_time),
                                    timeFormats.dayTime
                                )}
                            </td>
                            <td>{FieldLabelDict[l.updated_field]}</td>
                            <td>{getValiationLogDisplayVal(l)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        <div className="mt-6 text-center">
            <button className=" w-1/3 btn-secondary" onClick={onClose}>
                Close
            </button>
        </div>
    </div>
);

ValidationLogs.propTypes = {
    logs: PropTypes.shape({
        username: PropTypes.string.isRequired,
        record_time: PropTypes.string.isRequired,
        logs: PropTypes.arrayOf(
            PropTypes.shape({
                lamppost_id: PropTypes.string.isRequired,
                observation_time: PropTypes.string.isRequired,
                updated_field: PropTypes.string.isRequired,
                updated_conc: PropTypes.number,
                updated_flag: PropTypes.string
            })
        )
    }).isRequired,
    lamppostDict: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};
